<!--

Pagination
------------

Props
- total: total number of pages
- current: current page
- delta (default: 1): how many pages to show before & after current
- gap (default: '...'): ellipsis separator when too many pages

-->
<template>
  <ul class="flex w-full items-center justify-center gap-1">
    <li
      v-if="current > 1"
      class="mr-4 flex items-center p-2">
      <NuxtLink
        :to="{ path, query: { ...route.query, page: current - 1 } }"
        @click="emit('update:current', current - 1)">
        <Icon name="arrow-drop-left-line" />
      </NuxtLink>
    </li>
    <li
      v-else
      class="mr-4 p-2" />
    <li
      v-for="(page, index) in pages"
      :key="index"
      :class="[
        'flex items-center px-2 text-neutral-700',
        {
          'aspect-square min-h-0 rounded-full bg-neutral-800 px-4 text-white':
            page == current
        }
      ]">
      <div v-if="page === gap">
        {{ page }}
      </div>
      <NuxtLink
        v-else
        :to="{ path, query: { ...route.query, page } }"
        @click="emit('update:current', page)">
        {{ page }}
      </NuxtLink>
    </li>
    <li
      v-if="current < pages.length"
      class="ml-4 flex items-center p-2">
      <NuxtLink
        :to="{ path, query: { ...route.query, page: current + 1 } }"
        @click="emit('update:current', current + 1)">
        <Icon name="arrow-drop-right-line" />
      </NuxtLink>
    </li>
    <li
      v-else
      class="ml-4 p-2" />
  </ul>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    total: number
    current: number
    delta?: number
    gap?: string
    path: string
  }>(),
  {
    delta: 1,
    gap: '...'
  }
)

const route = useRoute()

const emit = defineEmits(['update:current'])

const pages = computed(() =>
  makePagination(props.total, props.current, props.gap, props.delta)
)

// generate pages number array
// source: https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=4320758#gistcomment-4320758
const makePagination = (
  total: number,
  current: number,
  gap: string,
  delta: number
) => {
  if (total <= 1) return [1]

  const center = [current] as (number | typeof gap)[]

  // no longer O(1) but still very fast
  for (let i = 1; i <= delta; i++) {
    center.unshift(current - i)
    center.push(current + i)
  }

  const filteredCenter = center.filter((page) => page > 1 && page < total)

  const includeLeftGap = current > 3 + delta
  const includeLeftPages = current === 3 + delta
  const includeRightGap = current < total - (2 + delta)
  const includeRightPages = current === total - (2 + delta)

  if (includeLeftPages) filteredCenter.unshift(2)
  if (includeRightPages) filteredCenter.push(total - 1)
  if (includeLeftGap) filteredCenter.unshift(gap)
  if (includeRightGap) filteredCenter.push(gap)

  return [1, ...filteredCenter, total]
}
</script>
